// ** Initial State
const initialState = {
  modalState: false,
  favorite_reason_codes: [],
  favorite_products: [],
};

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_MODAL_CONVO":
      return { ...state, modalState: !state.modalState };
    case "TOGGLE_MODAL_CLOSE_CONVO":
      return { ...state, modalState: false };
    case "SET_FAVORITE_CONVERSATION_REASON_CODES":
      return { ...state, favorite_reason_codes: action.payload };
    case "SET_FAVORITE_PRODUCTS":
      return { ...state, favorite_products: action.payload };
    default:
      return { ...state };
  }
};

export default navbarReducer;
