// ** React Imports
import { useState, createContext } from "react";
// ** Intl Provider Import
import { IntlProvider } from "react-intl";
// ** User Language Data
import userMessagesEn from "@src/assets/data/locales/en.json";
import userMessagesTr from "@src/assets/data/locales/tr.json";
import userMessagesSa from "@src/assets/data/locales/sa.json";
import userMessagesDe from "@src/assets/data/locales/de.json";
import userMessagesRu from "@src/assets/data/locales/ru.json";
import userMessagesFr from "@src/assets/data/locales/fr.json";
import userMessagesAm from "@src/assets/data/locales/am.json";
import userMessagesOm from "@src/assets/data/locales/om.json";
import userMessagesEs from "@src/assets/data/locales/es.json";
import { useEffect } from "react";

// ** Moment Imports
import moment from "moment";
import "moment/locale/tr";

// ** Menu msg obj
const menuMessages = {
  en: userMessagesEn,
  tr: userMessagesTr,
  sa: userMessagesSa,
  de: userMessagesDe,
  ru: userMessagesRu,
  fr: userMessagesFr,
  am: userMessagesAm,
  om: userMessagesOm,
  es: userMessagesEs,
};

// ** Create Context
const Context = createContext();

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState(menuMessages["en"]);

  console.log(locale);

  // ** Switches Language
  const switchLanguage = (lang) => {
    setLocale(lang);
    setMessages(menuMessages[lang]);
    localStorage.setItem("spc_selected_language", lang);
    moment.locale(lang);
  };

  useEffect(() => {
    const lang = localStorage.getItem("spc_selected_language");
    if (Object.keys(menuMessages).includes(lang)) {
      switchLanguage(lang);
    }
  }, []);

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider
        key={locale || "en"}
        locale={locale || "en"}
        messages={messages || ""}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
