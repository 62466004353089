const initialState = {
  todoModalOpen: {
    status: false,
    modalType: "add",
    id: null,
  },
  deleteModalOpen: {
    status: false,
    id: null,
  },
  tasks: [],
};

const todolist = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_TASKS":
      return {
        ...state,
        tasks: action.tasks,
      };

    case "TOGGLE_TODO_MODAL":
      return {
        ...state,
        todoModalOpen: {
          status: !state.todoModalOpen.status,
          modalType: action.modalType ? action.modalType : "add",
          id: action.id ? action.id : null,
        },
      };

    case "CHANGE_STATUS":
      return {
        ...state,
        tasks: state.tasks
          .map((task) => {
            if (task._id === action.id) {
              return {
                ...task,
                status: action.status,
              };
            }
            return task;
          })
          .sort((a, b) => {
            return a.status - b.status;
          }),
      };

    case "DELETE_TASK":
      return {
        ...state,
        tasks: state.tasks.filter((item) => {
          return item._id !== action.id;
        }),
      };

    case "CREATE_TASK":
      return {
        ...state,
        tasks: [
          ...state.tasks,
          {
            createdAt: action.data.createdAt,
            created_user_id: action.data.created_user_id,
            description: action.data.description,
            status: action.data.status,
            title: action.data.title,
            _id: action.data._id,
            updated_at: action.data.updated_at,
          },
        ],
      };

    case "UPDATE_TASK":
      return {
        ...state,
        tasks: state.tasks?.map((task) => {
          if (task._id === action.id) {
            return {
              ...task,
              title: action.data.title,
              description: action.data.description,
            };
          }
          return task;
        }),
      };

    case "TOGGLE_DELETE_MODAL":
      return {
        ...state,
        deleteModalOpen: {
          status: !state.deleteModalOpen.status,
          id: action.id ? action.id : null,
        },
      };

    default:
      return { ...state };
  }
};

export default todolist;
